// ==============================================
// Mixins
// ==============================================

@use './variables';

@mixin dot(
  $width: variables.$dot-width,
  $height: variables.$dot-height,
  $radius: variables.$dot-radius,
  $bg-color: variables.$dot-bg-color,
  $color: variables.$dot-color
) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bg-color;
  color: $color;
}