// ==============================================
// Variables
// ==============================================

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-radius: $dot-width * .5 !default;

$dot-color: #EB5947 !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

$dot-spacing: $dot-width + $dot-width * .5 !default;